<template>
    <div>
        <header-portrate /> 
        <div class="m-2" style="margin-bottom:70px !important;">
            <bread-crumbs :items="items"></bread-crumbs> 
            <tabs-comp :tabs="tabs" />
            <v-row :style="`direction:`+lang.dir">
                <v-col
                cols="12"
                md="8"
                sm="12">
                <b-input-group class="mt-3">
                    <b-form-input placeholder="Start Date" style="background:#eee" type="date" v-model="sdate"></b-form-input>
                    <b-form-input placeholder="End Date" style="background:#eee" type="date" v-model="edate"></b-form-input>
                    <b-input-group-append style="border-left:1px solid #CCC">
                        <b-button variant="light" style="background: #ccc !important;font-size:14px;color:#000;font-size:14px;padding:8px 22px;border-radius:5px" @click="getReport()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                    </b-input-group-append>
                </b-input-group>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="text-right">
                    <b-button variant="light" style="width:145px;margin-right:9px" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                    <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th colspan="6" class="text-center">تقرير الدخل اليومي</th>
                            </tr>
                            <tr>
                                <th class="text-center">{{lang.date}}</th>
                                <th class="text-center">الدخل</th>
                                <th class="text-center">المصروف</th>
                                <th class="text-center">نقدا</th>
                                <th class="text-center">اخرى</th>
                                <th class="text-center">الرصيد</th>
                            </tr>
                            <tr>
                                <th class="text-left" colspan="5">رصيد سابق</th>
                                <th class="text-center">{{lastBalance}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in report" :key="index">
                                <td class="text-center" style="direction:ltr">{{item.date}}</td>
                                <td class="text-center" style="direction:ltr">{{item.income}}</td>
                                <td class="text-center" style="direction:ltr">{{item.outcome}}</td>
                                <td class="text-center" style="direction:ltr">{{item.cash}}</td>
                                <td class="text-center" style="direction:ltr">{{item.others}}</td>
                                <td class="text-center" style="direction:ltr" v-html="parseFloat(item.balance)"></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th> الاجمالي  </th>
                                <th>{{totals.inTotal}} </th>
                                <th>{{totals.outTotal}} </th>
                                <th>{{totals.cash}} </th>
                                <th>{{totals.others}} </th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        <Footer />
    </div>
</template>

<script>

import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'
export default{
    name: 'InOutReport',
    components: {BreadCrumbs, TabsComp, HeaderPortrate, Footer},
    data() {
        return {
            items: {},
            sdate: '',
            edate: '',
            inoutRows: []
        }
    },
    computed:{ 
        ...mapGetters({
            inOutState: `inOutReport/inOutState`
        }),
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function() {
            let tabs =  [
                {
                    index:3,name:this.lang.vat_report,href:'/reports/vat-reports',class:'mytab3',smclass:'font-small'
                },
               
            ];

            tabs.push(
                {
                    index:4,name:this.lang.income_report,href:'/reports/income-report',class:'mytab',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:7,name:this.lang.account_statement,href:'/reports/statment-report',class:'mytab3',smclass:'font-small'
                }
            );
            
            tabs.push(
                {
                    index:8,name:this.lang.account_balance_report,href:'/reports/balance-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:8,name:this.lang.bnl_report,href:'/reports/bnl-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:8,name:this.lang.balance_report,href:'/reports/acbalance-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:2,name:this.lang.sales_report,href:'/reports/sales-reports',class:'mytab3',smclass:'font-small'
                }
            );
            
            tabs.push({
                    index:5,name:this.lang.return_report,href:'/reports/return-reports',class:'mytab6',smclass:'font-small'
                }
            );
            tabs.push({
                    index:0,name:this.lang.received_voucher,href:'/finance/inreceipt',class: 'mytab4',smclass:'font-small'
                }
            );
            tabs.push({
                    index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab5',smclass:'font-small'
                }
            );
            return tabs;
        },
        report: function () {
            return this.inOutState.report
        },
        totals: function(){
            let t = {
                inTotal: 0,
                outTotal: 0,
                cash: 0,
                others: 0,
            }
            for(const item in this.report){
                t.inTotal = parseFloat(t.inTotal) + parseFloat(this.report[item].income);
                t.outTotal = parseFloat(t.outTotal) + parseFloat(this.report[item].outcome);
                t.cash = parseFloat(t.cash) + parseFloat(this.report[item].cash);
                t.others = parseFloat(t.others) + parseFloat(this.report[item].others);
            }
            return t;
        },
        lastBalance: function(){
            let t = 0;
            for(const item in this.report){
                t = parseFloat(this.report[item].balance) - parseFloat(this.report[item].income) - parseFloat(this.report[item].outcome)
                break
            }
            return t;
        }
    },
    methods:{
        ...mapActions({
            getInOutRep: `inOutReport/getInOutRep`
        }),
        getReport() {
            this.getInOutRep({sdate:this.sdate,edate:this.edate})
        },
        async exportE(e){
            const post = new FormData();
            post.append('export','incomereport');
            console.log(this.report)
            let i = 0;
            for(const repo in this.report){
                
                for(const rep in this.report[repo]){
                    post.append('data['+i+']['+rep+']',this.report[repo][rep]);
                }
                i++;
            }
            post.append('sdate',this.sdate);
            post.append('edate',this.edate);
            post.append('startbalance',this.lastBalance);
            post.append('auth',this.$cookies.get('SAMCCCC-Cookies'));
            const response = await axios.post(
                '/api/excel.php',post
            )
            
            
            if(response && response.data){
                window.open("/api/"+response.data.url)
            }
        }
    },
    created(){
        this.getInOutRep({sdate:this.sdate,edate:this.edate})
    }
}
</script>